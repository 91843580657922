import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import submitEmail from '../../utils/submit-email';

const styles = theme => ({
  paperStyle: {
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(9)
    }
  },
  inputPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  buttonContainer: {
    'text-align': 'left',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1)
  }
});

class ContactForm extends React.Component {
  state = {
    isSubmitted: false,
    isLoading: false,
    isSubmitSuccessful: false
  };

  handleSubmit = async event => {
    try {
      event.preventDefault();
      this.setState({ isLoading: true });

      const formDetails = {
        name: event.target.name.value,
        email: event.target.email.value,
        message: event.target.message.value,
        phone: event.target.phone.value
      };

      const { result } = await submitEmail(formDetails);

      if (result === 'success') {
        this.showSuccessResult();
      } else {
        this.showErrorResult();
      }
    } catch (err) {
      this.showErrorResult();
    }
  };

  showSuccessResult = () =>
    this.setState({
      isSubmitted: true,
      isSubmitSuccessful: true,
      isLoading: false
    });

  showErrorResult = () =>
    this.setState({
      isSubmitted: true,
      isSubmitSuccessful: false,
      isLoading: false
    });

  render() {
    const { classes, labels } = this.props;
    const { isSubmitted, isLoading, isSubmitSuccessful } = this.state;

    return (
      <Paper className={classes.paperStyle} elevation={8}>
        <form onSubmit={this.handleSubmit}>
          <Typography variant="h3" component="h2" align="center" gutterBottom>
            {labels.form_title}
          </Typography>

          <TextField
            label={labels.name_placeholder}
            name="name"
            type="text"
            required
            fullWidth
            className={classes.inputPadding}
            inputProps={{
              minLength: 2,
              maxLength: 250
            }}
          />

          <TextField
            label={labels.email_placeholder}
            name="email"
            type="email"
            required
            fullWidth
            className={classes.inputPadding}
            inputProps={{
              minLength: 3,
              maxLength: 120
            }}
          />
          <TextField
            label={labels.phone_placeholder}
            name="phone"
            type="phone"
            required
            fullWidth
            className={classes.inputPadding}
            inputProps={{
              minLength: 3,
              maxLength: 120
            }}
          />
          <TextField
            label={labels.message_placeholder}
            name="message"
            type="text"
            multiline
            rows="5"
            required
            fullWidth
            inputProps={{
              minLength: 50,
              maxLength: 1000
            }}
          />
          <Typography variant="body2" component="p" align="left" gutterBottom>
            {labels.disclaimer}
          </Typography>
          <div className={classes.buttonContainer}>
            {/* show thank you */}
            {isSubmitted && isSubmitSuccessful && (
              <div>
                <Typography variant="h6" color="primary">
                  Thank You!
                </Typography>
                <Typography variant="body1">We will be in touch!</Typography>
              </div>
            )}
            {isSubmitted && !isSubmitSuccessful && (
              <div>
                <Typography variant="h6">Something went wrong!</Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  href="mailto:stoyan@mkit.io?subject=Get%20in%20touch%20%7C%20MK%20IT%20Ltd.&body="
                >
                  Get in touch
                </Button>
              </div>
            )}

            {!isSubmitted && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="primary" />
                ) : (
                  labels.button_label
                )}
              </Button>
            )}
          </div>
        </form>
      </Paper>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.shape({
    paperStyle: PropTypes.string,
    inputPadding: PropTypes.string,
    buttonContainer: PropTypes.string
  }).isRequired,
  labels: PropTypes.shape({
    email_label: PropTypes.string,
    phone_label: PropTypes.string,
    name_label: PropTypes.string,
    message_label: PropTypes.string,
    form_title: PropTypes.string,
    disclaimer: PropTypes.string,
    button_label: PropTypes.string
  }).isRequired
};
export default withStyles(styles)(ContactForm);
