import uuid from 'uuid';
import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

import Marker from './Marker';

const Map = withScriptjs(
  withGoogleMap(({ locations }) => (
    <GoogleMap defaultZoom={2} defaultCenter={{ lat: 41.903, lng: 12.496 }}>
      {locations &&
        locations.map(props => <Marker {...props} key={uuid.v4()} />)}
    </GoogleMap>
  ))
);

export default Map;
