import React from 'react';
import PropTypes from 'prop-types';
import { Marker as GMarker, InfoWindow } from 'react-google-maps';

class Marker extends React.Component {
  state = {
    markerDetails: false
  };

  handleMouseOver = () => {
    this.setState({ markerDetails: true });
  };

  handleMouseExit = () => {
    this.setState({ markerDetails: false });
  };

  render() {
    const { info, lat, lng } = this.props;
    const { markerDetails: showInfoWindow } = this.state;
    return (
      <GMarker
        position={{ lat, lng }}
        onMouseOver={this.handleMouseOver}
        onFocus={this.handleMouseOver}
        onMouseOut={this.handleMouseExit}
        onBlur={this.handleMouseExit}
      >
        {showInfoWindow && (
          <InfoWindow>
            <h4>{info}</h4>
          </InfoWindow>
        )}
      </GMarker>
    );
  }
}

Marker.propTypes = {
  info: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired
};

export default Marker;
