import React from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql } from 'gatsby';

import Grid from '@material-ui/core/Grid';
import Page from '../layout/Page';
import Container from '../layout/Container';
import Map from '../components/map/Map';
import ContactForm from '../components/contact-form/ContactForm';
import ContactInformation from '../components/contact-information/ContactInformation';
import Cta from '../components/cta/Cta';

const Contacts = ({ data, location }) => {
  const { prismicContacts } = data;
  if (!prismicContacts) {
    if (typeof window !== 'undefined') {
      navigate('/');
    }
    console.error('Page has not been translated.');
    return null;
  }

  const { data: prismicData } = prismicContacts;
  const {
    page_title,
    page_description,
    page_keywords,
    page_image,
    phone_numbers,
    phone_label,
    phone_placeholder,
    emails,
    email_label,
    email_placeholder,
    addresses,
    address_label,
    control_contact_name,
    control_contact_phone,
    control_contact_email,
    control_contact_name_label,
    control_contact_phone_label,
    control_contact_label,
    control_contact_email_label,
    locations,
    cta_title,
    cta_subtitle,
    cta_button,
    message_placeholder,
    name_placeholder,
    button_label,
    form_title,
    page_heading,
    page_subheading,
    disclaimer
  } = prismicData;

  return (
    <Page
      seo={{
        page_title,
        page_description,
        page_keywords,
        page_image,
        pathname: location.pathname
      }}
    >
      <Container size={100} isCenter>
        <Grid
          container
          item
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item container direction="column" xs={12} md={4}>
            <ContactInformation
              phones={phone_numbers}
              addresses={addresses}
              emails={emails}
              labels={{
                phone_label,
                email_label,
                address_label,
                page_heading,
                page_subheading
              }}
              control={{
                control_contact_name,
                control_contact_phone,
                control_contact_email,
                control_contact_name_label,
                control_contact_phone_label,
                control_contact_label,
                control_contact_email_label
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactForm
              labels={{
                name_placeholder,
                email_placeholder,
                phone_placeholder,
                message_placeholder,
                disclaimer,
                form_title,
                button_label
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Map
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBh39AOqadOxJkDso_4uoonyF8sUVMX3mQ"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `60vh` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        locations={locations}
      />

      <Container background="#1976d2">
        <Cta title={cta_title} subtitle={cta_subtitle} button={cta_button[0]} />
      </Container>
    </Page>
  );
};

Contacts.propTypes = {
  data: PropTypes.shape({
    prismicContacts: PropTypes.shape({
      data: PropTypes.shape().isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Contacts;

export const query = graphql`
  query contactQuery($locale: String!) {
    prismicContacts: prismicContacts(lang: { eq: $locale }) {
      lang
      data {
        page_title
        page_description
        page_keywords
        page_image {
          alt
          copyright
          url
        }
        phone_numbers {
          phone
        }
        emails {
          email
        }
        addresses {
          address
        }
        control_contact_name
        control_contact_phone
        control_contact_email
        locations {
          info
          lat
          lng
        }
        cta_title
        cta_subtitle
        cta_button {
          title
          to
        }
        address_label
        email_label
        email_placeholder
        message_placeholder
        name_placeholder
        phone_label
        phone_placeholder
        form_title
        disclaimer
        button_label
        control_contact_name_label
        control_contact_phone_label
        control_contact_label
        control_contact_email_label
        page_heading
        page_subheading
      }
    }
  }
`;
