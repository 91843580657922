import uuid from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';
import PinDrop from '@material-ui/icons/PinDrop';
import LocalHospital from '@material-ui/icons/LocalHospital';
import Link from '../link/Link';

const ContactInformation = ({ phones, emails, addresses, control, labels }) => (
  <React.Fragment>
    <Grid item>
      <Typography variant="h4" component="h1" gutterBottom>
        {labels.page_heading}
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="h5" component="h2" gutterBottom>
        {labels.page_subheading}
      </Typography>
    </Grid>

    <Grid
      item
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <Phone color="primary" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" component="p" gutterBottom>
          {labels.phone_label}:
        </Typography>
      </Grid>
      <Grid item>
        <Grid container item direction="column" justify="flex-end">
          {phones &&
            phones.map(({ phone }) => (
              <Grid item key={uuid.v4()}>
                <Link to={`tel:${phone}`}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    gutterBottom
                    key={uuid.v4()}
                  >
                    {phone}
                  </Typography>
                </Link>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>

    <Grid
      item
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <Email color="primary" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" component="p" gutterBottom>
          {labels.email_label}:
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" justify="flex-end">
          {emails &&
            emails.map(({ email }) => (
              <Grid item key={uuid.v4()}>
                <Link to={`mailto:${email}`}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    gutterBottom
                    key={uuid.v4()}
                  >
                    {email}
                  </Typography>
                </Link>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>

    <Grid
      item
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <PinDrop color="primary" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" component="p" gutterBottom>
          {labels.address_label}:
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" justify="flex-end">
          {addresses &&
            addresses.map(({ address }) => (
              <Grid item key={uuid.v4()}>
                <Link to={`http://maps.google.com/maps?q=${address}`}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    gutterBottom
                    key={uuid.v4()}
                  >
                    {address}
                  </Typography>
                </Link>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>

    <Grid
      item
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <LocalHospital color="primary" />
      </Grid>
      <Grid item>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {control.control_contact_label}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {control.control_contact_name_label}:{' '}
              {control.control_contact_name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {control.control_contact_phone_label}:{' '}
              <Link to={`tel:${control.control_contact_phone}`}>
                {control.control_contact_phone}
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            {control.control_contact_email && (
              <Typography
                variant="subtitle1"
                component="p"
                gutterBottom
                style={{ marginBottom: '25px' }}
              >
                {control.control_contact_email_label} :{' '}
                <Link to={`mailto:${control.control_contact_email}`}>
                  {control.control_contact_email}
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </React.Fragment>
);

ContactInformation.propTypes = {
  phones: PropTypes.arrayOf(PropTypes.object).isRequired,
  emails: PropTypes.arrayOf(PropTypes.object).isRequired,
  addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.shape({
    control_contact_name: PropTypes.string.isRequired,
    control_contact_phone: PropTypes.string.isRequired,
    control_contact_email: PropTypes.string,
    control_contact_name_label: PropTypes.string.isRequired,
    control_contact_phone_label: PropTypes.string.isRequired,
    control_contact_label: PropTypes.string.isRequired,
    control_contact_email_label: PropTypes.string.isRequired
  }).isRequired,
  labels: PropTypes.shape({
    phone_label: PropTypes.string.isRequired,
    email_label: PropTypes.string.isRequired,
    address_label: PropTypes.string.isRequired,
    page_heading: PropTypes.string.isRequired,
    page_subheading: PropTypes.string.isRequired
  }).isRequired
};
export default ContactInformation;
